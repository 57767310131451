import "./chat.css"
import { useParams } from "react-router-dom";
import Heade from '../../Components/Header'
import Moldura from '../../Components/asserts/molduracelular.png'
import axios from "axios";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import whats from '../../Components/asserts/whats.webp'
import instagram from '../../Components/asserts/instagram.png'
import celular from '../../Components/asserts/telefone.png'
import seachbar from '../../Components/asserts/seachbar.png'
import Swal from "sweetalert2";
import {i18n} from '../../Translate/i18n'
import useGeoLocation from "react-ipgeolocation";
import { Link } from 'react-router-dom';
import Forteer from "../../Components/Forteer";

import Men from '../../Components/asserts/mens.png'
import Status from '../../Components/asserts/satus.png'
import Balao from '../../Components/asserts/balao.png'
import Adduser from '../../Components/asserts/adduser.png'
import Config from '../../Components/asserts/config.png'
import { IoIosArrowRoundBack ,IoIosCamera,IoIosMic      } from "react-icons/io";
import Addmens from '../../Components/asserts/admens.png'
import Pontos from '../../Components/asserts/pontos.png'
import Lupa from '../../Components/asserts/lupa.png'

import p1 from '../../Components/asserts/p1.webp'
import p3 from '../../Components/asserts/p3.webp'
import p6 from '../../Components/asserts/p6.webp'
import p7 from '../../Components/asserts/ft2.jpeg'
import p8 from '../../Components/asserts/imag.jpg'
import perfil from '../../Components/asserts/perfil.jpg'
import p10 from '../../Components/asserts/images.jpg'
import p11 from '../../Components/asserts/des.jpg'
import p12 from '../../Components/asserts/img2.webp'
import p13 from '../../Components/asserts/img1.webp'
import p14 from '../../Components/asserts/img8.jpg'
import check from '../../Components/asserts/check.png'
import logo from '../../Components/asserts/custom.png'
import { IoIosMore } from "react-icons/io";
import { IoIosHappy } from "react-icons/io";
import { IoMdAttach } from "react-icons/io";

 function Chat() {
  const location = useGeoLocation();
  const {telefone}= useParams();
  const [foto,setFoto] = useState("")
  const [loading,setLoading] = useState(true)
  const [city,setCity] = useState('')
  const [butoes,setButoes] = useState('Tudo')
  const numeroped = telefone.substr(0, 6);
  const data = new Date();
  const horas = data.getHours();
  const minutos = data.getMinutes();
  const athoras = horas + ':' + minutos;
  const [ite,setIte] = useState('')

  const posts = [
    {
      id:"1",
      phone:`${numeroped}*******`,
      perfil:p10,
      coments:"o que vc acha?",
      hora:"",
      cont:"5"
    },
    {
      id:"2",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
      hora:"",
      cont:""
    },
   
    {
      id:"3",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
      hora:"",
      cont:"3"
    },
    {
      id:"4",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
       hora:"",
        cont:""
       
       
    },
    {
      id:"5",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
      hora:"",
       cont:""
    },
    {
      id:"7",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
      hora:"",
      cont:"2"
    },
    {
      id:"9",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
      hora:"",
       cont:""
    },
    {
      id:"10",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
      hora:"",
       cont:""
    },
    {
      id:"11",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
       hora:"",
        cont:""
    },
    {
      id:"12",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
       hora:"",
        cont:""
    },
    {
      id:"13",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
      hora:"",
       cont:""
    },
    {
      id:"14",
      phone:`${numeroped}*******`,
      perfil:perfil,
      coments:"Conteúdo boqueado",
      hora:"",
       cont:""
    }
    
  ]

  
  useEffect(()=>{
   
    async function foto(){
        try {
          
  
          const response = await axios.get(`https://whatsapp-data1.p.rapidapi.com/number/${telefone.replace("+", "")}`,{
            headers: {
              'X-Rapidapi-Key' : '3f9cbd0771msh47895941b9b358dp1a4977jsn0df0ed49bbc8'
          }})
  
     
          
        console.log(response.data)
        setFoto(response.data.profilePic)
  
     
  
          
        } catch (error) {
          console.error(error);
          
        }
      }
  
      
      foto()
    


  },[])

function exibir(){
  Swal.fire({
    title: `${telefone}`,
    imageUrl:foto,
    imageWidth:70,
    confirmButtonText: `Liberar acesso`,
    text: `Tenha acesso completo aos grupos desse whatsapp por apenas 29,90, e a todas as provas coletadas.`,
   
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
        window.open("https://checkout.perfectpay.com.br/pay/PPU38CP37Q7?utm_source=tk")
  
    } else if (result.isDenied) {
      Swal.fire("Changes are not saved", "", "info");
    }
  });
}

function imgperfil(){
    Swal.fire({
        title: `${telefone}`,
        imageUrl:foto,
        imageWidth:70,
        confirmButtonText: `Liberar acesso`,
        text: `Tenha acesso completo ao celular dessa pessoa por apenas R$29,90, e a todas as provas coletadas.`,
       
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            window.open("https://checkout.perfectpay.com.br/pay/PPU38CP37Q7?utm_source=tk")
      
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
}

function configs(){
    Swal.fire({
        title: `Quem somos?`,
        imageUrl:logo,
        imageWidth:150,
        confirmButtonText: `Entendi`,
        text: `BACKSPY é um aplicativo espião capaz de acessar o celular de pessoas apenas com número.`,
       
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
           
      
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
}




  return (
    <div className="ctChat">
     
   <div className="bxicons">
   <div className="iconss"><button onClick={imgperfil}>{foto ? <img src={foto} width={40}/> : <img src={perfil} width={40}/>}</button></div>
   <div className="icons"><button onClick={configs}><img src={Config} width={30}/></button></div>

<div  className="icons"><button onClick={exibir}><img src={Balao} width={30}/></button></div>
<div  className="icons"><button onClick={exibir}><img src={Adduser} width={30}/></button></div>

<div className="divcont"></div>

<div>
<div  className="icons"><button onClick={exibir}><img src={Status} width={30}/></button></div>
<div className="icons"><button onClick={exibir}><img src={Men} width={30}/></button></div>

</div>

   </div>

   

   <div className="bxchat">

{
  ite == '' ?
 <div>
   <div className="headerchat">

<div className="iconsheader"><span>Chats</span></div>
<div className="iconsheader"><button><img src={Addmens} width={30}/></button></div>
<div className="iconsheader"><button><img src={Pontos} width={30}/></button></div>
    </div>
    <a href="https://checkout.perfectpay.com.br/pay/PPU38CP37Q7?utm_source=tk" className="btnliberar">Liberar acesso R$29,90</a>
    <div className="contseach">
    <div className="seachicons"><img src={Lupa} width={15}/></div>
<div className="seachicons"><span>Pesquisar</span></div>
    </div>

    <div className="textcont">
        <div className="texticons"><button onClick={()=> setButoes('Tudo')}>Tudo</button></div>
        <div className="texticons"><button onClick={()=> setButoes('Não')}>Não lidas</button></div>
        <div className="texticons"><button onClick={()=> setButoes('Grupos')}>Grupos</button></div>

    </div>

   
      <div className="listtodos">
      {
        butoes == "Tudo" ? 
        
        <div>
{
    posts.map((item)=>{
        return(
            <div className="lista" key={item.id}>
                <button onClick={()=>setIte(item)}>
<div className="bxlistacont">
   <div> <img src={item.perfil} width={40}/></div>
    <div className="bxlist">

<div className="iconslist">
  <div><span>{item.phone}</span></div>
  <div className="sepli"></div>
  
    </div>

<div className="iconslist">
    <img src={check} width={10} height={10}/>
    <span>{item.coments}</span>

    
    </div>


    

    </div>

    <div className="bxverd">
    <div className="iconslisthora"><span>{item.hora}</span></div>
    <div className="bxverde">{item.cont ? <span>{item.cont}</span> : <p>--</p>}</div>
    </div>

    </div>

    </button>
            </div>
        )
    })
}


        </div> : <div>Carregando...</div> && butoes == 'Não' ? 
        
        <div>
       {
    posts.map((item)=>{
        return(
            <div className="lista" key={item.id}>
              {
                item.cont ? <div>
                        <button onClick={()=>setIte(item)}>
<div className="bxlistacont">
   <div> <img src={item.perfil} width={40}/></div>
    <div className="bxlist">

<div className="iconslist">
  <div><span>{item.phone}</span></div>
  <div className="sepli"></div>
  
    </div>

<div className="iconslist">
    <img src={check} width={10} height={10}/>
    <span>{item.coments}</span>

    
    </div>


    

    </div>

    <div className="bxverd">
    <div className="iconslisthora"><span>{item.hora}</span></div>
    <div className="bxverde">{item.cont ? <span>{item.cont}</span> : <p>--</p>}</div>
    </div>

    </div>

    </button>
         
                </div> : <div></div>
              }
            </div>
        )
    })
}

        </div> : <div></div> && butoes == 'Grupos' ? 
        <div>
          {
    posts.map((item)=>{
        return(
            <div className="lista" key={item.id}>
               {
                item.perfil == perfil ? <div>
                   <button onClick={exibir}>
<div className="bxlistacont">
   <div> <img src={item.perfil} width={40}/></div>
    <div className="bxlist">

<div className="iconslist">
  <div><span>Grupo Bloqueado</span></div>
  <div className="sepli"></div>
  
    </div>

<div className="iconslist">
    <img src={check} width={10} height={10}/>
    <span>{item.coments}</span>

    
    </div>


    

    </div>

    <div className="bxverd">
    <div className="iconslisthora"><span>{item.hora}</span></div>
    <div className="bxverde">{item.cont ? <span>{item.cont}</span> : <p>--</p>}</div>
    </div>

    </div>

    </button>
                </div> : <div></div>
               }
            </div>
        )
    })
}
        </div> : <div></div> 
      }
    </div>
 </div> :
  
 <div className="listitem">
 
  
      {
       
        <div className="contlistchatitem">
     <div className="menusubchat">
     <button onClick={()=>setIte("")}><IoIosArrowRoundBack/></button>
        <img src={ite.perfil} />
     
          <div><a href="https://checkout.perfectpay.com.br/pay/PPU38CP37Q7?utm_source=tk" className="btnliberar">Liberar acesso R$29,90</a></div>
          
     </div>

     <div className="listchats">

<div className="divultimas"><span>Ultima atualização</span></div>

<div className="botcont">
  <div className="botchat">
  <div className="icotres"><IoIosMore color="#949aa2"/></div>
  <div className="boxchatbot">Conteúdo Bloqueado 🔒.</div>
  </div>
  <span>Horário Bloqueado🔒</span>
</div>

<div className="botcont2">

<div className="imgbotcont2">
  <img src={perfil}/>
</div>

<div className="box2cont">

<div className="bxicon2">
<div className="boxchatbot1">Conteúdo Bloqueado 🔒.</div>
<div className="icotres"><IoIosMore color="#949aa2"/></div>
</div>

<span>Horário Bloqueado🔒</span>

</div>

</div>

<div className="botcont">
  <div className="botchat">
  <div className="icotres"><IoIosMore color="#949aa2"/></div>
  <div className="boxchatbot">Conteúdo Bloqueado 🔒.</div>
  </div>
  <span>Horário Bloqueado🔒</span>
</div>

<div className="botcont">
  <div className="botchat">
  <div className="icotres"><IoIosMore color="#949aa2"/></div>
  <div className="boxchatbot">Conteúdo Bloqueado 🔒.</div>
  </div>
  <span>Horário Bloqueado🔒</span>
</div>

<div className="botcont">
  <div className="botchat">
  <div className="icotres"><IoIosMore color="#949aa2"/></div>
  <div className="boxchatbot">Conteúdo Bloqueado 🔒.</div>
  </div>
  <span>Horário Bloqueado🔒</span>
</div>


<div className="botcont2">

<div className="imgbotcont2">
  <img src={perfil}/>
</div>

<div className="box2cont">

<div className="bxicon2">
<div className="boxchatbot1">Conteúdo Bloqueado 🔒.</div>
<div className="icotres"><IoIosMore color="#949aa2"/></div>
</div>

<span>Horário Bloqueado🔒</span>

</div>

</div>

<div className="botcont">
  <div className="botchat">
  <div className="icotres"><IoIosMore color="#949aa2"/></div>
  <div className="boxchatbot">Conteúdo Bloqueado 🔒.</div>
  </div>
  <span>Horário Bloqueado🔒</span>
</div>


<div className="botcont2">

<div className="imgbotcont2">
  <img src={perfil}/>
</div>

<div className="box2cont">

<div className="bxicon2">
<div className="boxchatbot1">Conteúdo Bloqueado 🔒.</div>
<div className="icotres"><IoIosMore color="#949aa2"/></div>
</div>

<span>Horário Bloqueado🔒</span>

</div>

</div>

<div className="botcont">
  <div className="botchat">
  <div className="icotres"><IoIosMore color="#949aa2"/></div>
  <div className="boxchatbot">Conteúdo Bloqueado 🔒.</div>
  </div>
  <span>Horário Bloqueado🔒</span>
</div>


<div className="botcont2">

<div className="imgbotcont2">
  <img src={perfil}/>
</div>

<div className="box2cont">

<div className="bxicon2">
<div className="boxchatbot1">Conteúdo Bloqueado 🔒.</div>
<div className="icotres"><IoIosMore color="#949aa2"/></div>
</div>

<span>Horário Bloqueado🔒</span>

</div>

</div>

<div className="botcont">
  <div className="botchat">
  <div className="icotres"><IoIosMore color="#949aa2"/></div>
  <div className="boxchatbot">Conteúdo Bloqueado 🔒.</div>
  </div>
  <span>Horário Bloqueado🔒</span>
</div>

<div className="botcont">
  <div className="botchat">
  <div className="icotres"><IoIosMore color="#949aa2"/></div>
  <div className="boxchatbot">Conteúdo Bloqueado 🔒.</div>
  </div>
  <span>Horário Bloqueado🔒</span>
</div>


     </div>


     <div className="bootmlistitem">

<div className="fxbot">

<div >
  <IoIosHappy size={40} color="#b8b8b8" /> 
  </div>
  
<div className="inputbx"><textarea className="form-control" rows="1" placeholder="escreva aqui"></textarea></div>

<div className="ico"><IoMdAttach size={30} /><IoIosCamera  size={30} /></div>
<div className="microicon"><IoIosMic size={20} color="#FFF" /></div>

</div>

     </div>

        </div>
        
      }


      
      </div>
    }


   



   </div>


   <div className="bxespaco"></div>
        
    </div>
      );
}
 

export default Chat;