import { useState } from 'react';
import './heade.css'
import {i18n} from '../../Translate/i18n'
import { FaArrowLeft,FaRedo,FaEllipsisV } from "react-icons/fa";
import Logo from '../asserts/logo.png';






function header() {



    return(
        <header>
    <img width={250} src={Logo} />
        </header>
    )
}

export default header;