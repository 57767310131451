import './App.css';
import 'react-phone-number-input/style.css'
import React,{useState,useEffect, createElement} from 'react';
import Header from "../../Components/Header";
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import useGeoLocation from "react-ipgeolocation";
import {i18n} from '../../Translate/i18n';
import WhatsappIcon from '../../Components/asserts/custom.png'
import iphone from '../../Components/asserts/iphone.svg'
import macewindows from '../../Components/asserts/mac-or-windows.svg'
import windowsphone from '../../Components/asserts/windows-phone.svg'
import android from '../../Components/asserts/android.svg'
import p1 from '../../Components/asserts/p3.webp'
import p3 from '../../Components/asserts/ft1.jpeg'
import p6 from '../../Components/asserts/p6.webp'
import p7 from '../../Components/asserts/ft2.jpeg'
import p8 from '../../Components/asserts/p3.webp'
import lg from '../../Components/asserts/lg.png'
import p10 from '../../Components/asserts/img3.webp'
import p11 from '../../Components/asserts/img5.jpeg'
import p12 from '../../Components/asserts/img2.webp'
import p13 from '../../Components/asserts/img1.webp'
import p14 from '../../Components/asserts/img4.jpeg'
import Zap from '../../Components/asserts/zap.webp'
import { useParams,useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from 'react-modal';
import LoadingBar from 'react-top-loading-bar'
import {FaCheckCircle } from "react-icons/fa";
import Footer from '../../Components/Forteer'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RadioButton } from 'primereact/radiobutton';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
Modal.setAppElement('#root');



function Home() {
    const [telefone, setTelefone] = useState("");
    const location = useGeoLocation();
    const I18N_STORAGE_KEY = 'i18nextLng'
    let subtitle;
    const navigate = useNavigate()
    const [modalIsOpen, setIsOpen] = useState(false);
    const [progress, setProgress] = useState(0)
    const [ip, setIp] = useState(0)
    const [st, setst] = useState('bl')
    const [stm, setstm] = useState('blk')
    const [bv, setbv] = useState('bl')
    const [bm, setbm] = useState('bl')
    const [bz, setbz] = useState('bl')
    const [bd, setbd] = useState('bl')
    const [ba, setba] = useState('bl')
    const [bs, setbs] = useState('bl')
    const [abtn, setabtn] = useState('abtn')
    const [start, setStart] = useState(false)
    const data = new Date();
    const novadata = data.toLocaleDateString();
    const posts = [
      {
        id:"1",
        nome:"Juana008",
        perfil:p1,
        coments:"La aplicación realmente funciona"
      },
      {
        id:"2",
        nome:"Mariasilva675",
        perfil:p3,
        coments:"Muy buena aplicación"
      },
     
      {
        id:"3",
        nome:"Michelle28747",
        perfil:p6,
        coments:"Vi todas sus conversaciones"
      },
      {
        id:"7",
        nome:"jade6832",
        perfil:p10,
        coments:"vi todas las fotos"
      },
      {
        id:"9",
        nome:"manuelle374",
        perfil:p13,
        coments:"Gracias espectadorespy"
      },
      {
        id:"10",
        nome:"Cintia235",
        perfil:p12,
        coments:"Simplemente fantástico"
      },
      {
        id:"12",
        nome:"Selma6384",
        perfil:p14,
        coments:"Muy bien"
      },
      {
        id:"13",
        nome:"Katia245",
        perfil:p11,
        coments:"Funcionou"
      }
    
      
    ]

    const categories = [
      { name: 'Marido/Novio', key: 'h' },
      { name: 'Esposa/Novia', key: 'm' },
   
  ];
  const [selectedCategory, setSelectedCategory] = useState(categories[1]);

 
const [lista,setLista] = useState([
  {
      "name":"Maria batista",
      "mensage":"Se unió a whatsapp +34 ** 9****-****",
      "iamge":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStCxpItcPwt5LKBFMJSl-PybrdRVh5zorqKg&s"
  },
  {
      "name":"Daiana silva",
      "mensage":"Se unió a whatsapp +34 ** 9****-****",
      "iamge":"https://i.pinimg.com/280x280_RS/f8/2f/e3/f82fe3fd8ee1069b5cba81866899f315.jpg"
  },
  {
      "name":"Stefany santos",
      "mensage":"Se unió a whatsapp +34 ** 9****-****",
      "iamge":"https://i.pinimg.com/280x280_RS/b6/f4/0c/b6f40c4bd7db65fed276b5184c89f9f7.jpg"
  },
  {
    "name":"Jaine maria",
    "mensage":"Se unió a whatsapp +34 ** 9****-****",
    "iamge":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR14X6cB8cR2mLbF_OM7ExtB5F3CGbjdGRrg&s"
},
{
  "name":"Gabriella Lins",
  "mensage":"Se unió a whatsapp +34 ** 9****-****",
  "iamge":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFH7-U-egBm6Gs2CuP0obb91KmfU8Kqc1qDaO4HKrvoWGZblYpH0aD7DC7W0iKjNVMW7w&usqp=CAU"
},

])


const Msg = ({Name,Mens,Imagem}) => (
<div style={{background:'white',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',height:30}}>

<div style={{background:'white',display:'flex',alignItems:'center',justifyContent:'center'}}>
<div className='blsss'></div>
    <img style={{width:40,height:40,borderRadius:'50%' }} src={Imagem}/>
   <div style={{marginLeft:10,display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'center'}}>
   <h1>{Name}</h1>
   <p style={{marginTop:-10}}>{Mens}</p>
   
   </div>


  </div>
</div>
);

  const displayMsg = (name,mensage,iamge) => {
    toast(<Msg Name={name} Mens={mensage} Imagem={iamge}/>);
    // toast(Msg) would also work
  };

    async function openModal() {
      setIsOpen(true);
      setStart(true)
   
      const response = await axios.get('https://ipapi.co/json/')

  setIp(response.data.ip)
    
    }
   
    function closeModal() {
      setIsOpen(false);
    }
   useEffect(()=>{






    function selectLanguagem(){
      localStorage.setItem(
        I18N_STORAGE_KEY,
        location.country
      )

     
     
    }

    selectLanguagem()

    posts.map(item=>{
     displayMsg(item.nome,item.coments,item.perfil)
   })


    
   },[])

   

   if(start == true){
    
    startbar()
    
  }else{
    
    clearTimeout(startbar)
   
  }

  function startbar(){
  
 setTimeout(()=>{
      
   
    setProgress(progress+1)
    
      
if(progress == 11){
  setst('bt')
  setstm('bt')

}else
if(progress == 21){
  setbv('blk')
 
}
else
if(progress == 31){

  setbm('bt')
  setbv('bc')
 
}
else
if(progress == 41){
  setbz('blk')
 
}
else
if(progress == 51){
  setbd('bt')
  setbz('bc')
 
}
else
if(progress == 61){
  setba('blk')

 
}
else
if(progress == 100){
  setbs('bt')
  setba('bc')
  setabtn('btna')
  setProgress(100)
  setStart(false)
 


 
}

 
 },1000)

  }
   



  return (
    <div className='containerBox'>
      <Header/>
    <ToastContainer
    position="top-center"
    hideProgressBar
    autoClose={3000}
    limit={1}
    
    />

  

      <div className='boxinfo'>
        

        <div style={{borderRadius:10,width:300,display:'flex',flexDirection:'column',alignItems:'flex-start',marginTop:10}}>
    <img src={lg} width={300} />
        </div>

        
        <h1 >1 - Seleccione su cónyuge <br></br>a continuación quien quiere clonar!</h1>
          
          
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <div style={{display:'flex',flexDirection:'column',marginLeft:75,marginTop:20}}>
                   {categories.map((category) => {
                       return (
                           <div key={category.key} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <label htmlFor={category.key} style={{marginTop:5,fontWeight:'bold'}}  >{category.name}</label>
                               <RadioButton  style={{marginLeft:5}} inputId={category.key} name="category" value={category} onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory.key === category.key} />
                              
                           </div>
                       );
                   })}
               </div>
   
          </div>

       <div className='separator'></div>
       <p><b>2 -Segundo selecciona tu país</b></p>

 

          <p className='ptexto' >3 - Ingresa el número que <br></br>deseas  clonar a continuación</p>
    
        <PhoneInput
              defaultCountry={location.country}
              value={telefone}
              initialValueFormat='international'
              onChange={setTelefone}
              placeholder="(00)00000-0000"
              error={telefone ? (isPossiblePhoneNumber(telefone) ? undefined : 'Invalid phone'):
            'Phone number requirer'

            }
          
             
          />
   
       <p style={{color:'#1d1d1f',fontSize:10}}>Datos protegidos mediante cifrado.</p>
       
       
        <Link to={`processando/${telefone}/${selectedCategory.key}`} >Romper el cifrado de WhatsApp</Link>
        <br/>
       
        <br/>

      
      

          <div className='bxdate'><h4>Cómo funciona la herramienta?</h4>
         <p style={{color:'#1d1d1f',textAlign:'center',marginLeft:30,marginRight:30}}>
         Ela Clona completamente el celular de la persona usando el número de teléfono, solo necesitas ingresarlo en el campo de arriba y clonar<br></br><br></br>
         No importa si la persona usa WhatsApp modificado o vive lejos de ti, la app lo clona TODO
         </p>
          </div>

        
          
     

         
      
      </div>

     </div>
     
  );
}

export default Home;
